import React, { useContext, useEffect, useState } from 'react';
import AppContext from '../../../../core/context/app.context';
import ReactMapboxGl from 'react-mapbox-gl';
import * as mapActions from '../../../../core/redux/actions/map.actions';
import { connect } from 'react-redux';
import { Button, Card, Header, Menu, Transition } from 'semantic-ui-react';
import Loader from '../../../../shared/components/loader';
import config from '../../../../config';
import MapRenderer from './mapRenderer';
import MapAssignmentsModal from '../modal/mapAssignmentsModal';
import MapGroupModal from '../modal/mapGroupModal';
import * as workOrderService from '../../../../core/services/workOrder.service';
import * as userService from '../../../../core/services/user.service';
import { ASSIGN_WORKORDERS_MUTATION, UNASSIGN_WORKORDERS_MUTATION } from '../../../../graphql/mutations/workorder';
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import MapSearch from '../mapSearch';
import MeterDeployDetailsModal from '../../../../shared/components/workorder-details/meterDeployDetails';
import WaterDeployDetailsModal from '../../../../shared/components/workorder-details/waterDeployDetails';
import WaterMeterDeployDetailsModal from '../../../../shared/components/workorder-details/waterMeterDeployDetails';
import WORKORDER_TYPES from '../../../../constants/workOrderTypes';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import './styles.css';
import QualificationWarningModal from '../../../../shared/components/modal/qualificationWarningModal';

let map = ReactMapboxGl({ accessToken: config.mapbox.accessToken });

const MapBox = ({
  syncGeoJsonData,
  createGeoJson,
  getMapLayoutData,
  geoJsonFileUrl,
  selectedWorkOrders,
  showAssignmentsModal,
  showGroupModal,
  setShowAssignmentsModal,
  setShowGroupModal,
  resetMapState,
  showMapToolbar,
  getFilterTypes,
  filterTypes,
  getWorkOrderDetails,
  polygons,
  isMapLoading,
  mapLoadingMessage,
  setSearchBy,
  setFilterBy,
  filterBy,
  searchBy,
  searchMap,
  setMapStatusId,
  setMounted
}) => {
  const appContext = useContext(AppContext);

  const [users, setUsers] = useState([]);
  const [workOrderGroups, setWorkOrderGroups] = useState([]);
  const [assignUserList, setAssignUserList] = useState([]);
  const [unAssignUserList, setUnAssignUserList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedWorkOrder, setSelectedWorkOrder] = useState(null);
  const [modalDetailsLoading, setModalDetailsLoading] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [workOrdersGroupName, setWorkOrdersGroupName] = useState('');
  const [drawControlRef, setDrawControlRef] = useState(null);
  const [showQualificationWarningModal, setShowQualificationWarningModal] = useState(false);

  useEffect(() => {
    setMounted(true);
    const { currentProject, setWorkOrdersPageMapViewActive, updateInvalidLocationCount } = appContext;
    const init = async () => {
      if (geoJsonFileUrl) {
        createGeoJson({
          projectId: currentProject.id,
          workOrderType: currentProject.projectWorkOrderType,
          refresh: true
        });
      } else {
        createGeoJson({
          projectId: currentProject.id,
          workOrderType: currentProject.projectWorkOrderType,
          init: true
        });
      }
      getMapLayoutData(currentProject.id, currentProject.projectWorkOrderType);
      const usersList = await userService.listProjectUserByWorkOrderStatusAndCount(currentProject.id);
      setUsers(usersList);
      const workOrderGroupsList = await workOrderService.listWorkOrderGroups(currentProject.id);
      setWorkOrderGroups(workOrderGroupsList);
      getFilterTypes();
      updateInvalidLocationCount();
    }
    init();
    return () => {
      setWorkOrdersPageMapViewActive(false);
      resetMapState();
      setMapStatusId('');
      setMounted(false);
    }
  }, []);

  useEffect(() => {
    map = ReactMapboxGl({ accessToken: config.mapbox.accessToken });
  }, [geoJsonFileUrl]);

  useEffect(() => {
    return () => {
      setSearchBy('');
      setFilterBy([]);
    }
  }, []);

  const onGetWorkOrderDetails = async (id) => {
    setModalDetailsLoading(true);
    const result = await getWorkOrderDetails(id);
    setSelectedWorkOrder(result);
    setModalDetailsLoading(false);
  }

  const hasQualifications = (user) => {
    const { currentProject } = appContext;
    const workOrderType = currentProject.projectWorkOrderType;
    const qualificationDefinitionKey = workOrderType === WORKORDER_TYPES.MeterDeploy || WORKORDER_TYPES.WaterMeterDeploy ? 'meterFormFactor' : 'meterSize';
    const featureKey = qualificationDefinitionKey === 'meterFormFactor' ? 'mff' : 'ms';
    const sizes = {};
    if (user.qualificationDefinition) {
      const qualificationDefinition = JSON.parse(user.qualificationDefinition);
      for (let currectWorkOrder of selectedWorkOrders) {
        const size = currectWorkOrder[featureKey];
        if (qualificationDefinition[qualificationDefinitionKey].indexOf(size) === -1) {
          sizes[size] = size;
        }
      }
    }
    if (Object.keys(sizes).length > 0) {
      setShowQualificationWarningModal(`Technician ${user.fullName} is not qualified for: ${Object.keys(sizes).join(', ')}`);
    }
  }

  const assignUser = (event, data, user) => {
    const userId = data.value;
    const assignUserListIndex = assignUserList.indexOf(userId);
    const unAssignUserListIndex = unAssignUserList.indexOf(userId);
    if (assignUserListIndex > -1) {
      assignUserList.splice(assignUserListIndex, 1)
    } else {
      hasQualifications(user);
      assignUserList.push(userId);
    }
    if (unAssignUserListIndex > -1) {
      unAssignUserList.splice(unAssignUserListIndex, 1);
    }
    setAssignUserList([...assignUserList]);
  }

  const unAssignUser = (event, data) => {
    const userId = data.value;
    const assignUserListIndex = assignUserList.indexOf(userId);
    const unAssignUserListIndex = unAssignUserList.indexOf(userId);
    unAssignUserListIndex > -1 ? unAssignUserList.splice(unAssignUserListIndex, 1) : unAssignUserList.push(userId);
    if (assignUserListIndex > -1) {
      assignUserList.splice(assignUserListIndex, 1);
    }
    setUnAssignUserList([...unAssignUserList]);
  }

  const closeAssignmentsModal = () => {
    setShowAssignmentsModal(false);
    setAssignUserList([]);
    setUnAssignUserList([]);
  }

  const closeGroupModal = () => {
    setShowGroupModal(false);
    setWorkOrdersGroupName('');
  }

  const handleAssignments = async (mutation, userList) => {
    const { currentProject } = appContext;
    return await workOrderService.workOrderAssignments(mutation, currentProject.id, userList, selectedWorkOrders.map(({ id }) => id), true);
  };

  const onAssignmentsModalSubmit = async () => {
    setLoading(true);
    const { currentProject } = appContext;
    let latestAssignmentsUrl;
    if (assignUserList.length > 0) {
      const response = await handleAssignments(ASSIGN_WORKORDERS_MUTATION, assignUserList);
      latestAssignmentsUrl = response.assignWorkOrders.latestAssignmentsUrl;
    }
    if (unAssignUserList.length > 0) {
      const response = await handleAssignments(UNASSIGN_WORKORDERS_MUTATION, unAssignUserList);
      latestAssignmentsUrl = response.unassignWorkOrders.latestAssignmentsUrl;
    }
    const users = await userService.listProjectUserByWorkOrderStatusAndCount(currentProject.id);
    setUsers(users);
    closeAssignmentsModal();
    if (latestAssignmentsUrl) {
      syncGeoJsonData(latestAssignmentsUrl, currentProject.id, currentProject.projectWorkOrderType, currentProject.projectTimeZone);
    }
    drawControlRef.draw.deleteAll();
    setLoading(false);
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      onSearch();
    }
  };

  const onSearch = () => {
    const { currentProject } = appContext;
    const { projectWorkOrderType, projectTimeZone, projectConfiguration } = currentProject;
    searchMap(projectWorkOrderType, projectTimeZone, projectConfiguration);
  }

  const onFilterTypeChange = (event) => {
    setFilterBy(event.target.value);
  }

  const onCloseWorkOrderDetailsModal = () => {
    setSelectedWorkOrder(null);
  }

  const onGallerySlide = (photoIndex) => {
    setPhotoIndex(photoIndex);
  }

  const updateWorkOrder = async (workOrderId, update) => {
    setModalDetailsLoading(true);
    const { currentProject } = appContext;
    if (typeof (update) === 'function') {
      await update();
    }
    const result = await getWorkOrderDetails(workOrderId);
    setSelectedWorkOrder(result);
    setModalDetailsLoading(false);
    createGeoJson({
      projectId: currentProject.id,
      workOrderType: currentProject.projectWorkOrderType,
      usePreviousLocation: true,
      search: true,
      refresh: true,
    });
  }

  const onSearchChange = (event) => {
    setSearchBy(event.target.value);
  }

  const onWorkOrderGroupNameChange = (event, { value }) => {
    setWorkOrdersGroupName(value);
  }

  const saveWorkOrdersGroup = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      const { currentProject } = appContext;
      if (workOrdersGroupName && workOrdersGroupName.trim().length > 0) {
        await workOrderService.saveWorkOrderGroups(currentProject, workOrdersGroupName, selectedWorkOrders.map(({ id }) => id));
        const workOrderGroupsList = await workOrderService.listWorkOrderGroups(currentProject.id);
        setWorkOrderGroups(workOrderGroupsList);
        getFilterTypes();
        createGeoJson({
          projectId: currentProject.id,
          workOrderType: currentProject.projectWorkOrderType,
          usePreviousLocation: true,
          search: true,
          refresh: true,
        });
        closeGroupModal();
        drawControlRef.draw.deleteAll();
        setLoading(false);
      } else {
        throw new Error();
      }
    } catch (error) {
      this.setState({ savingWorkOrdersGroup: false, error: true });
    }
  }

  const onMapStyleChange = (style) => {
    const { currentProject } = appContext;
    createGeoJson({
      projectId: currentProject.id,
      workOrderType: currentProject.projectWorkOrderType,
      refresh: true,
      style
    });
  }

  const renderDetailsModal = () => {
    const { currentProject, user } = appContext;
    const workOrderType = currentProject.projectWorkOrderType;
    if (selectedWorkOrder) {
      if (workOrderType === WORKORDER_TYPES.MeterDeploy) {
        return <MeterDeployDetailsModal
          getWorkOrderDetails={onGetWorkOrderDetails}
          setModalDetailsLoading={setModalDetailsLoading}
          user={user}
          modalDetailsLoading={modalDetailsLoading}
          selectedWorkOrder={selectedWorkOrder}
          closeModal={onCloseWorkOrderDetailsModal}
          showModal={true}
          onGallerySlide={onGallerySlide}
          photoIndex={photoIndex}
          currentProject={currentProject}
          updateWorkOrder={updateWorkOrder} />
      } else if (workOrderType === WORKORDER_TYPES.WaterMeterDeploy) {
        return <WaterMeterDeployDetailsModal
          getWorkOrderDetails={onGetWorkOrderDetails}
          setModalDetailsLoading={setModalDetailsLoading}
          user={user}
          modalDetailsLoading={modalDetailsLoading}
          selectedWorkOrder={selectedWorkOrder}
          closeModal={onCloseWorkOrderDetailsModal}
          showModal={true}
          onGallerySlide={onGallerySlide}
          photoIndex={photoIndex}
          currentProject={currentProject}
          updateWorkOrder={updateWorkOrder} />
      } else if (workOrderType === WORKORDER_TYPES.WaterDeploy || workOrderType === WORKORDER_TYPES.WaterSiteSurvey) {
        return <WaterDeployDetailsModal
          getWorkOrderDetails={onGetWorkOrderDetails}
          setModalDetailsLoading={setModalDetailsLoading}
          user={user}
          modalDetailsLoading={modalDetailsLoading}
          selectedWorkOrder={selectedWorkOrder}
          closeModal={onCloseWorkOrderDetailsModal}
          showModal={true}
          onGallerySlide={onGallerySlide}
          photoIndex={photoIndex}
          currentProject={currentProject}
          updateWorkOrder={updateWorkOrder} />
      }
    }
  }

  const renderToolbar = () => (
    <React.Fragment>
      {showMapToolbar && (
        <div className='workorders-mapview-tool-bar' onKeyPress={handleKeyPress}>
          <Menu stackable className='workorders-mapview-tool-bar-menu'>
            <Menu.Item className='workorders-mapview-tool-bar-menu-item'>
              <MultiSelect
                style={{ width: '100%' }}
                onChange={onFilterTypeChange}
                value={filterBy}
                label='Filter by...'
                data={filterTypes}
                textField='filterName'
              />
            </Menu.Item>
            <Menu.Item>
              <MapSearch
                value={searchBy}
                onChange={onSearchChange}
                onSearch={onSearch}
                placeholder='Search by old, new, cust, acct, street, loc...'
              />
            </Menu.Item>
          </Menu>
        </div>
      )}
    </React.Fragment>
  )

  const renderPolygonOptions = () => (
    <Transition visible={Object.values(polygons).length > 0} animation='scale' duration={500}>
      <Card className='options'>
        <Card.Header>
          <div className='options-header-container'>
            <Header className='options-header' as='h4'>{selectedWorkOrders.length > 0 ? 'Choose Option' : ''}</Header>
            <Header className='options-header' as='h4'>Selected: {selectedWorkOrders.length}</Header>
          </div>
        </Card.Header>
        <Card.Content className='options-content'>
          {selectedWorkOrders.length > 0 ? (
            <Button.Group className='button-group'>
              <Button onClick={() => setShowAssignmentsModal(true)} disabled={selectedWorkOrders.length > 500}>Assignments</Button>
              <Button.Or />
              <Button onClick={() => setShowGroupModal(true)} disabled={selectedWorkOrders.length > 1000}>Save Group</Button>
            </Button.Group>
          ) : (
            <span>No Data Found</span>
          )}
        </Card.Content>
        {selectedWorkOrders.length > 500 && <span style={{ fontSize: '.8em', marginLeft:'10px', position: 'relative', top: '-10px'}}>Assignment limit exceeded (500)</span>}
        {selectedWorkOrders.length > 1000 && <span style={{ display: 'block', fontSize: '.8em', marginLeft:'10px', position: 'relative', top: '-10px'}}>Save Group limit exceeded (1000)</span>}
      </Card>
    </Transition >
  )

  return (
    <React.Fragment>
      {renderDetailsModal()}
      {renderToolbar()}
      {showQualificationWarningModal && <QualificationWarningModal
        showModal={!!showQualificationWarningModal}
        message={showQualificationWarningModal}
        closeModal={() => setShowQualificationWarningModal('')} />}
      {showAssignmentsModal && <MapAssignmentsModal
        loading={loading}
        users={users}
        showModal={showAssignmentsModal}
        closeModal={closeAssignmentsModal}
        onSubmit={onAssignmentsModalSubmit}
        selectedWorkOrders={selectedWorkOrders}
        assignUsersList={assignUserList}
        unassingUsersList={unAssignUserList}
        assignUser={assignUser}
        unAssignUser={unAssignUser}
      />}
      {showGroupModal && <MapGroupModal
        loading={loading}
        showModal={showGroupModal}
        closeModal={closeGroupModal}
        selectedWorkOrders={selectedWorkOrders}
        saveWorkOrdersGroup={saveWorkOrdersGroup}
        workOrderGroups={workOrderGroups}
        onWorkOrderGroupNameChange={onWorkOrderGroupNameChange}
      />}
      {renderPolygonOptions()}
      { geoJsonFileUrl &&
        <MapRenderer
          setDrawControlRef={setDrawControlRef}
          user={appContext.user}
          onMapStyleChange={onMapStyleChange}
          component={map}
          onGetWorkOrderDetails={onGetWorkOrderDetails} />}
      <Loader loading={isMapLoading} message={mapLoadingMessage} />
    </React.Fragment>
  )
}

const mapStateToProps = ({ map }) => {
  return {
    selectedWorkOrders: map.selectedWorkOrders,
    geoJsonFileUrl: map.geoJsonFileUrl,
    showAssignmentsModal: map.showAssignmentsModal,
    showGroupModal: map.showGroupModal,
    showMapToolbar: map.showMapToolbar,
    polygons: map.polygons,
    isMapLoading: map.loading,
    mapLoadingMessage: map.loadingMessage,
    searchBy: map.searchBy,
    filterBy: map.filterBy,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createGeoJson: (options) => dispatch(mapActions.createGeoJson(options)),
    syncGeoJsonData: (url, projectId, workOrderType, projectTimeZone) => dispatch(mapActions.syncGeoJsonData(url, projectId, workOrderType, projectTimeZone)),
    getMapLayoutData: (projectId, workOrderType) => dispatch(mapActions.getLayoutData(projectId, workOrderType)),
    setShowAssignmentsModal: (showAssignmentsModal) => dispatch(mapActions.setShowAssignmentsModal(showAssignmentsModal)),
    setShowGroupModal: (showGroupModal) => dispatch(mapActions.setShowGroupModal(showGroupModal)),
    resetMapState: () => dispatch(mapActions.resetMapState()),
    setSearchBy: (searchBy) => dispatch(mapActions.setSearchBy(searchBy)),
    setFilterBy: (filterBy) => dispatch(mapActions.setFilterBy(filterBy)),
    searchMap: (workOrderType, projectTimeZone, projectConfiguration) => dispatch(mapActions.searchMap(workOrderType, true, projectTimeZone, projectConfiguration)),
    setMapStatusId: (id) => dispatch(mapActions.setMapStatusId(id)),
    setMounted: (mounted) => dispatch(mapActions.setMounted(mounted)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MapBox);