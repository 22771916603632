import React, { useState, useEffect } from 'react';
import WORKORDER_DETAILS_ICON from '../../../assets/icons/workorder-details.svg';
import { Grid, GridColumn as Column, GridColumnMenuFilter, GridColumnMenuCheckboxFilter } from '@progress/kendo-react-grid';
import { process as kendoDataQueryProcess } from '@progress/kendo-data-query';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Button } from '@progress/kendo-react-buttons';
import { Icon } from "@progress/kendo-react-common";



  const HeaderCell = (props) => {
    const filterContainsField = JSON.stringify(props.filter).includes(props.field);
    return (
      <span className="k-cell-inner">
        <span className="k-link" onClick={props.onClick}>
          <span className="k-column-title">
            {props.title}
            { filterContainsField && <Icon className={'header-icon'} style={{ fontSize: '14px !important', paddingLeft: '4px !important' }} name='filter' size='xsmall'/> }
            {props.children}
          </span>
        </span>
      </span>
  );
}


const ColumnMenu = (props) => {
  return (
    <div>
      <GridColumnMenuFilter {...props} expanded={true} />
    </div>
  );
}



const ColumnMenuCheckboxFilter = (props) => {
  // for some reason I couldn't get this to take an array of strings?
  const data = props.data.map(x => ({ [props.column.field]: x }));
    return (
      <div>
        <GridColumnMenuCheckboxFilter {...props} data={data} expanded={true} />
      </div>
    );
}



export const DropdownFilterCell = (props) => {
  let hasValue = (value) => Boolean(value && value !== props.defaultItem);
  const onChange = (event) => {
    hasValue = hasValue(event.target.value);
    props.onChange({
      value: hasValue ? event.target.value : '',
      operator: hasValue ? 'eq' : '',
      syntheticEvent: event.syntheticEvent,
    });
  };

  const onClearButtonClick = (event) => {
    event.preventDefault();
    props.onChange({
      value: '',
      operator: '',
      syntheticEvent: event,
    });
  };

  return (
    <div className='k-filtercell'>
      <DropDownList
        style={{ fontWeight: 400 }}
        data={props.data}
        onChange={onChange}
        value={props.value || props.defaultItem}
        defaultItem={props.defaultItem}
      />
      <Button
        title='Clear'
        disabled={!hasValue(props.value)}
        onClick={onClearButtonClick}
        icon='filter-clear'
      />
    </div>
  );
};




function CustomViewCell(props) {
  const { workOrderNumberCopy } = props.dataItem;
  const aggregateIconStyle =  workOrderNumberCopy ? {
    alignSelf: 'center',
    cursor: 'pointer'
  } : {
    alignSelf: 'center',
    color: '#a1a1a1'
  };
  const handleAggregateClick = () => {
    if(!props.dataItem.workOrderNumberCopy) return console.warn('No results')
    props.onDataStateChange({
      dataState: {
        filter: {
          filters: [{
            field: 'workOrderNumber',
            operator: 'contains',
            value: props.dataItem.workOrderNumberCopy
          }],
          logic: 'and'
        }
      }
    });
  }
  return (
    <td
      {...props.tdProps}
      colSpan={1}
      style={{
        color: props.color,
        backgroundColor: props.backgroundColor,
        textAlign: 'center',
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'center', gap: 20 }}>
        <div>
          <img
            onClick={() => props.handleOpenWODetails(props.dataItem.id)}
            style={{ cursor: 'pointer', height: '16px', width: '16px' }}
            src={WORKORDER_DETAILS_ICON}
            alt='workorder-details'
          />
        </div>
        <Icon onClick={handleAggregateClick} style={aggregateIconStyle} name="aggregate-fields" />
      </div>
    </td>
  );
}



function WorkOrdersGrid({ data: workOrders, handleOpenWODetails, dataState, setDataState }) {
  const [dataResult, setDataResult] = useState(
    kendoDataQueryProcess(workOrders.map(wo => ({
      ...wo,
      // Only create Date objects for non-null dates
      appointmentStartDate: wo.appointmentStartDate ? new Date(wo.appointmentStartDate) : null,
      dateEnrolled: wo.dateEnrolled ? new Date(wo.dateEnrolled) : null
    })), dataState)
  );
  const [page, setPage] = useState({
    skip: 0,
    take: 100,
  });

  const BooleanColumnMenu = (props) => {
    const data = props.data.map(x => ({ [props.column.field]: x }));
    return (
      <div>
        <GridColumnMenuCheckboxFilter 
          {...props} 
          data={data} 
          expanded={true}
          searchBox={false}
        />
      </div>
    );
  }

  const TruncatedCell = (props) => {
    return (
      <td title={props.dataItem[props.field]} style={{ 
        maxWidth: 0,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
      }}>
        {props.dataItem[props.field]}
      </td>
    );
  }

  useEffect(() => {
    setDataResult(kendoDataQueryProcess(workOrders.map(wo => ({
      ...wo,
      appointmentStartDate: wo.appointmentStartDate ? new Date(wo.appointmentStartDate) : null,
      dateEnrolled: wo.dateEnrolled ? new Date(wo.dateEnrolled) : null
    })), { filter: dataState.filter, sort: dataState.sort }));
  }, [workOrders]);

  useEffect(() => {
    setDataResult(kendoDataQueryProcess(workOrders.map(wo => ({
      ...wo,
      appointmentStartDate: wo.appointmentStartDate ? new Date(wo.appointmentStartDate) : null,
      dateEnrolled: wo.dateEnrolled ? new Date(wo.dateEnrolled) : null
    })), { filter: dataState.filter, sort: dataState.sort }));
    setPage({
      skip: 0,
      take: 100,
    });
  }, [dataState]);

  const onDataStateChange = (e) => {
    console.log(e)
    const { filter, sort } = e.dataState;
    setDataState({ filter, sort });
  };

  const pageChange = (event) => {
    const take = event.page.take
    setPage({
      ...event.page,
      take,
    });
  }

  const workOrderStatusList = [...new Set(workOrders.map(item => item.workOrderStatus))];
  const workOrderCallList = [...new Set(workOrders.map(item => item.bookCode))].filter(group => group !== null);
  const workOrderDistrictList = [...new Set(workOrders.map(item => item.districtCode))].filter(group => group !== null);

  return (
    <div className='csr-grid' style={{ overflow: 'scroll' }}>
      <Grid
        resizable={true}
        sortable={true}
        data={dataResult.data.slice(page.skip, page.take + page.skip)}
        skip={page.skip}
        take={page.take}
        total={dataResult.data.length}
        pageable={{
          buttonCount: 4,
          pageSizeValue: 10,
        }}
        onPageChange={pageChange}
        {...dataState}
        onDataStateChange={onDataStateChange}
      >
        <Column width={'100px'} headerCell={(props) => <HeaderCell {...props} filter={dataState.filter} />} cell={(props) => <CustomViewCell {...props} onDataStateChange={onDataStateChange} handleOpenWODetails={handleOpenWODetails}/>} field='view' title='View' sortable={false} filterable={false} />
        <Column width={'180px'} headerCell={(props) => <HeaderCell {...props} filter={dataState.filter} />} columnMenu={(props) => <ColumnMenu { ...props } hideSecondFilter={true}/>} field='workOrderNumber' title='Work Order' />
        <Column width={'50px'} headerCell={(props) => <HeaderCell {...props} filter={dataState.filter} />} columnMenu={(props) => <ColumnMenuCheckboxFilter {...props} data={workOrderDistrictList}/>} field='districtCode' title='Dist' />
        <Column width={'150px'} headerCell={(props) => <HeaderCell {...props} filter={dataState.filter} />} columnMenu={(props) => <ColumnMenu { ...props } hideSecondFilter={true}/>} field='customerName' title='Name' cell={TruncatedCell} />
        <Column width={'150px'} headerCell={(props) => <HeaderCell {...props} filter={dataState.filter} />} columnMenu={(props) => <ColumnMenu { ...props } hideSecondFilter={true}/>} field='address' title='Address' cell={TruncatedCell} />
        <Column width={'105px'} headerCell={(props) => <HeaderCell {...props} filter={dataState.filter} />} columnMenu={(props) => <ColumnMenuCheckboxFilter {...props} data={workOrderStatusList} />} field='workOrderStatus' title='Status' />
        <Column width={'125px'} headerCell={(props) => <HeaderCell {...props} filter={dataState.filter} />} columnMenu={(props) => <ColumnMenuCheckboxFilter {...props} data={workOrderCallList} />} field='bookCode' title='Call Status' />
        <Column width={'110px'} headerCell={(props) => <HeaderCell {...props} filter={dataState.filter} />} columnMenu={(props) => <ColumnMenu { ...props }/>} filter={'date'} format={'{0:MM/dd/yyyy HH:mm}'} field='appointmentStartDate' title='Appt' />
        <Column width={'100px'} headerCell={(props) => <HeaderCell {...props} filter={dataState.filter} />} columnMenu={(props) => <ColumnMenu { ...props }/>} filter={'date'} format={'{0:MM/dd/yyyy}'} field='dateEnrolled' title='Enrolled' />
        <Column width={'90px'} headerCell={(props) => <HeaderCell {...props} filter={dataState.filter} />} columnMenu={(props) => <BooleanColumnMenu {...props} data={[true, false]} />} field='workOrderFlaggedAs3strike' title='Turndown' />
        <Column width={'90px'} headerCell={(props) => <HeaderCell {...props} filter={dataState.filter} />} columnMenu={(props) => <BooleanColumnMenu {...props} data={[true, false]} />} field='workOrderFlaggedAsCNC' title='Skip' />
        <Column width={'90px'} headerCell={(props) => <HeaderCell {...props} filter={dataState.filter} />} columnMenu={(props) => <BooleanColumnMenu {...props} data={[true, false]} />} field='workOrderNeedsAppointment' title='Scheduled?' />
        <Column width={'90px'} headerCell={(props) => <HeaderCell {...props} filter={dataState.filter} />} columnMenu={(props) => <BooleanColumnMenu {...props} data={[true, false]} />} field='workOrderFlaggedAsAdHoc' title='ServiceCall' />
      </Grid>
    </div>
    
  )
}



export default WorkOrdersGrid;
